import Api from '@api';

import clientCommon from './blocks/common'
import clientTag from './blocks/tag'

import { formConfig } from './config/formConfig'

export default {
  name: 'upiLineDetail',
  components: {
    clientCommon,
    clientTag
  },
  data() {
    return {
      current:'',
      clientCommonData: {
        _current: ''
      },
    };
  },
  computed:{
    injectData(){
      return this.$route.query || { _current:'' }
    }
  },
  activated() {
    this.$nextTick(()=>{
      this.init();
    })
  },
  mounted() {
    this.$nextTick(()=>{
      this.init();
    })
  },
  methods: {
    init(){
      this.getOrderLineData();
    },
    async getOrderLineData() {
      this.setCurrent('line')
      if(!this.injectData.CLIENT_ID && !this.injectData.LINE_ID) return ;
      let { status, data } = await Api.GetUPILineInfo({CLIENT_ID: this.injectData.CLIENT_ID,PALLET_ID: this.injectData.PALLET_ID,LINE_ID: this.injectData.LINE_ID});
      if (Number(status) === 200) {

        let result = JSON.parse(JSON.stringify(data));
        this.$refs.clientCommon.$refs.clientCommon.formData = result;

        formConfig(this).thead
          .filter(item => item !== "THead")
          .forEach(item => {
            this.$refs.clientTag.$refs[item][0].formData = result
          })
      }
    },
    getOrderHeaderData(){
      this.$router.push({
        path:'/UPIHeadDetail',
        query:{
          CLIENT_ID: this.injectData.CLIENT_ID,
          PALLET_ID: this.injectData.PALLET_ID
        }
      })
    },
    /*
    * 设置当前查询的是行还是列
    * */
    setCurrent(name){
      this.clientCommonData._current = name
      this.current = name
    },
    toGetLineList(){
      this.$store.commit('SET_CURRENT_HEADERS',{model:'upi',header:[this.injectData.PALLET_ID]})
      this.$store.commit('SET_CURRENT_TAB',{model:'upi',currentTab:'lineCom'})
      this.$router.push('/UPI')
    },
    handleConfirm() {},
    handleClose() {
      this.$emit('close')
    }
  }
}